<template>
	<el-dialog title="Series" :visible="showDialog" @close="close" @open="getData">
		<v-simple-table dense v-if="records.length > 0">
			<template v-slot:default>
				<thead>
					<tr>
						<th>Tipo de documento</th>
						<th>Número</th>
						<th class="text-center">D. Contingencia</th>
						<th class="text-right">Quitar</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, index) in records" :key="index">
						<template v-if="row.id">
							<td>{{ row.document_type_description }}</td>
							<td>{{ row.number }}</td>
							<td class="text-center">{{ row.contingency ? 'SI' : 'NO' }}</td>
							<td class="text-right">
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn
											color="accent"
											class="ma-1"
											fab
											x-small
											dark
											@click.prevent="clickDelete(row.id)"
											v-on="on"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</template>
									<span>Eliminar</span>
								</v-tooltip>
							</td>
						</template>
						<template v-else>
							<td>
								<div
									class="form-group mb-0"
									:class="{ 'error--text': row.errors.document_type_id }"
								>
									<el-select v-model="row.document_type_id">
										<el-option
											v-for="option in documentTypes"
											:key="option.id"
											:value="option.id"
											:label="option.description"
										></el-option>
									</el-select>
									<small
										class="form-control-feedback"
										v-if="row.errors.document_type_id"
										v-text="row.errors.document_type_id[0]"
									></small>
								</div>
							</td>
							<td>
								<div
									class="form-group mb-0"
									:class="{ 'error--text': row.errors.number }"
								>
									<el-input v-model="row.number" :maxlength="4"></el-input>
									<small
										class="form-control-feedback"
										v-if="row.errors.number"
										v-text="row.errors.number[0]"
									></small>
								</div>
							</td>
							<td>
								<div class="col-md-3 center-el-checkbox">
									<div
										class="form-group"
										:class="{ 'error--text': row.errors.contingency }"
									>
										<el-checkbox
											v-model="row.contingency"
											@change="filterDocumentType(row)"
											>Contingencia</el-checkbox
										>
										<small
											class="form-control-feedback"
											v-if="row.errors.contingency"
											v-text="row.errors.contingency[0]"
										></small>
									</div>
								</div>
							</td>
							<td class="text-right">
								<v-btn
									color="secondary"
									fab
									x-small
									dark
									@click.prevent="clickSubmit(index)"
								>
									<v-icon>mdi-check-all</v-icon>
								</v-btn>
								<v-btn
									color="accent"
									class="mx-1"
									fab
									x-small
									dark
									@click.prevent="clickCancel(index)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</td>
						</template>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<v-row>
			<v-col cols="12" xl="12">
				<el-button type="primary" icon="el-icon-plus" @click="clickAddRow"
					>Nuevo</el-button
				>
			</v-col>
		</v-row>
	</el-dialog>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import filter from 'lodash/filter';

export default {
	props: ['showDialog', 'establishmentId'],
	mixins: [deletable],
	data() {
		return {
			resource: 'series',
			records: [],
			documentTypes: [],
			allDocumentTypes: [],
			showAddButton: true,
		};
	},
	created() {},
	methods: {
		initForm() {
			this.records = [];
			this.showAddButton = true;
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.allDocumentTypes = response.data.documentTypes;
				this.initDocumentTypes();
			});
		},
		async getData() {
			await this.initForm();
			await this.getTables();
			if (this.establishmentId) {
				await this.$http
					.get(`/${this.resource}/records/${this.establishmentId}`)
					.then((response) => {
						if (response.data !== '') {
							this.records = response.data.data;
						}
					});
			}
		},
		clickAddRow() {
			this.records.push({
				id: null,
				document_type_id: null,
				number: null,
				contingency: false,
				errors: {},
				loading: false,
			});
			this.showAddButton = false;
		},
		clickCancel(index) {
			this.records.splice(index, 1);
			this.initDocumentTypes();
			this.showAddButton = true;
		},
		clickSubmit(index) {
			let form = {
				id: this.records[index].id,
				establishment_id: this.establishmentId,
				document_type_id: this.records[index].document_type_id,
				number: this.records[index].number,
				contingency: this.records[index].contingency,
			};
			this.$http
				.post(`/${this.resource}`, form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.getData();
						this.initDocumentTypes();
						this.showAddButton = true;
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				});
		},
		filterDocumentType(row) {
			if (row.contingency) {
				this.documentTypes = filter(
					this.allDocumentTypes,
					(item) =>
						item.id == '01' || item.id == '03' || item.id == '07' || item.id == '08'
				);
				row.document_type_id =
					this.documentTypes.length > 0 ? this.documentTypes[0].id : null;
			} else {
				row.document_type_id = null;
				this.documentTypes = this.allDocumentTypes;
			}
		},
		initDocumentTypes() {
			this.documentTypes = this.allDocumentTypes.length > 0 ? this.allDocumentTypes : [];
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initDocumentTypes();
			this.initForm();
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(
				() => this.getData(),
				this.initDocumentTypes()
			);
		},
	},
};
</script>
